import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

// Home

const MarketingAgencyPage = lazy(() => import("./Pages/Home/MarketingAgency"))

//blog 
const BlogGridPage = lazy(() => import("./Pages/Blogs/BlogGrid"))
const BlogStandardPostPage = lazy(() => import("./Pages/Blogs/PostTypes/BlogStandardPostPage"))

// Portfolio and project
const PortfolioClassicTwoColPage = lazy(() => import("./Pages/Portfolios/PortfolioClassic/PortfolioClassicTwoColumn"))
const SingleProjectPage01 = lazy(() => import("./Pages/Portfolios/SingleProjectPage01"))


// About Pages
const AboutUsPage = lazy(() => import("./Pages/About/AboutUsPage"))
const TeamsPage = lazy(() => import("./Pages/Teams/TeamsPage"))

const OurStoryPage = lazy(() => import("./Pages/About/OurStoryPage"))
const WhoWeArePage = lazy(() => import("./Pages/About/WhoWeArePage"))

// Services Pages
const OurServicesPage = lazy(() => import("./Pages/Services/OurServicesPage"))
const WhatWeOfferPage = lazy(() => import("./Pages/Services/WhatWeOfferPage"))
const OurProcessPage = lazy(() => import("./Pages/Services/OurProcessPage"))

// Contact Pages
const ContactUsModernPage = lazy(() => import("./Pages/Contact/ContactUsModernPage"))


function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<MarketingAgencyPage />} />
                  <Route path="/blog" element={<BlogGridPage style={{ "--base-color": "#0038e3" }} />} />

                  {/*About Pages */}
                  <Route path="/about" element={<AboutUsPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/Teams" element={<TeamsPage style={{ "--base-color": "#0038e3" }} />} />

                  <Route path="/page/our-story" element={<OurStoryPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/who-we-are" element={<WhoWeArePage style={{ "--base-color": "#0038e3" }} />} />

                  <Route path="Projects" element={<PortfolioClassicTwoColPage style={{ "--base-color": "#fff" }} />} />
                  <Route path="/project/byid" element={<SingleProjectPage01 style={{ "--base-color": "#0038e3" }} />} />


                  {/* Services Pages */}
                  <Route path="/page/our-services" element={<OurServicesPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/what-we-offer" element={<WhatWeOfferPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/our-process" element={<OurProcessPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="blog-types">
                    <Route path="blog-standard-post">
                      <Route path=":id" element={<BlogStandardPostPage style={{ "--base-color": "#0038e3" }} />} />
                    </Route>
                  </Route>

                  {/* Contact Pages */}
                  <Route path="/contact" element={<ContactUsModernPage style={{ "--base-color": "#0038e3" }} />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;